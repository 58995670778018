@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --primary-color: #1e88e5;
    --accent-color: #007483;
    --text-color: #1a1a1a;
    --background-color: #ffffff;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Inter', sans-serif;
    color: var(--text-color);
    background-color: var(--background-color);
    min-height: 100vh;
    margin: 0;
}


.coming-soon-container {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, rgb(0 0 0 / 100%) 0%, rgb(35 130 213 / 80%) 100%);
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    /* Ensures the video stays in the background */
}


/* .coming-soon-container {
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    background: linear-gradient(135deg, rgb(0 0 0 / 100%) 0%, rgb(35 130 213 / 80%) 100%), url(https://w0.peakpx.com/wallpaper/1004/117/HD-wallpaper-technology-texture-blue-technology-background-creative-blue-background-technology-background-blue-neon-background-blue-neon-abstraction.jpg) no-repeat center center fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
} */

/* .coming-soon-container::before {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(135deg,
            rgba(255, 255, 255, 0.95) 0%,
            rgba(255, 255, 255, 0.85) 50%,
            rgba(255, 255, 255, 0.75) 100%);
    z-index: 0;
} */

.content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 2rem 0;
    position: relative;
    z-index: 1;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
}

.logo {
    height: 40px;
}

.social-links {
    display: flex;
    gap: 1.5rem;
}

.icon {
    width: 24px;
    height: 24px;
    color: var(--background-color);
    transition: color 0.3s ease;
}

.icon:hover {
    color: var(--primary-color);
}

.main-content {
    max-width: 600px;
}

.coming-soon-badge {
    display: inline-block;
    padding: 1rem 0;
    /* background-color: var(--primary-color); */
    color: white;
    /* border-radius: 2rem; */
    font-size: 3rem;
    margin-bottom: 2rem;
}

.title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    color: var(--primary-color);
}

.subtitle {
    font-size: 1.125rem;
    color: #fff;
    margin-bottom: 2rem;
}

.countdown {
    margin-bottom: 2rem;
}

.countdown-units {
    display: flex;
    gap: 2rem;
}

.countdown-unit {
    text-align: center;
}

.countdown-unit .number {
    display: block;
    font-size: 5rem;
    margin: 1rem 0;

    font-weight: 700;
    color: var(--primary-color);
}

.countdown-unit .label {
    font-size: 0.875rem;
    color: #fff;
    text-transform: uppercase;
}

.signup-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 3rem;
}

.signup-form input {
    padding: 0.75rem 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 0.5rem;
    font-size: 1rem;
    transition: border-color 0.3s ease;
}

.signup-form input:focus {
    outline: none;
    border-color: var(--primary-color);
}

.email-submit {
    display: flex;
    gap: 1rem;
}

.email-submit input {
    flex: 1;
}

button {
    padding: 0.75rem 2rem;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0f1657;
}

.products {
    margin-top: 4rem;
}

.products h2 {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
    color: var(--primary-color);
}

.product-logos {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.product-logos img {
    height: 30px;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }

    .email-submit {
        flex-direction: column;
    }

    .countdown-units {
        gap: 1rem;
    }

    .countdown-unit .number {
        font-size: 1.5rem;
    }

    .product-logos {
        flex-direction: column;
        align-items: flex-start;
    }
}